import Vue from 'vue';
import VueRouter from 'vue-router';
import ls from 'local-storage';
import store from '@/store/index.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // HOMEPAGE ROUTER
    {
      path: '/',
      name: 'home',
      beforeEnter: ifIsAuthenticated,
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home - Painel de Controle',
            active: true,
          },
        ],
      },
    },
    // WORKSPACE ROUTES
    {
      path: '/workspaces',
      name: 'workspaces',
      beforeEnter: isSuperAdmin,
      component: () =>
        import('@/views/pages/administration/workspaces/ListWorkspaces.vue'),
      meta: {
        pageTitle: 'Lista de Áreas de trabalho',
        breadcrumb: [
          {
            text: 'Áreas de trabalho',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-workspace',
      name: 'new-workspace',
      beforeEnter: isSuperAdmin,
      component: () =>
        import(
          '@/views/pages/administration/workspaces/components/FormWorkspace.vue'
        ),
    },
    {
      path: '/usuarios/dashboard/:id',
      name: 'dashboard-usuarios',
      //beforeEnter: isManager,
      component: () =>
        import(
          '@/views/pages/administration/users/components/DashBoardUsers.vue'
        ),
    },
    {
      path: '/update-workspace/:id',
      name: 'update-workspace',
      beforeEnter: isSuperAdmin,
      component: () =>
        import(
          '@/views/pages/administration/workspaces/components/UpdateWorkspace.vue'
        ),
    },
    {
      path: '/workspaces-users/:id',
      name: 'workspaces-users',
      beforeEnter: isSuperAdmin,
      component: () =>
        import(
          '@/views/pages/administration/workspaces/components/ListUsersWorkspace.vue'
        ),
      meta: {
        pageTitle: 'Usuários Vinculados',
        breadcrumb: [
          {
            text: 'Áreas de trabalho',
            active: false,
            to: { name: 'workspaces' },
          },
          {
            text: 'Lista de Usuários',
            active: true,
          },
        ],
      },
    },
    {
      path: '/update-user-workspace/:id/:workspace_id',
      name: 'update-user-workspace',
      beforeEnter: isSuperAdmin,
      component: () =>
        import(
          '@/views/pages/administration/workspaces/components/UpdateUsersWorkspace.vue'
        ),
    },
    {
      path: '/link-user-workspace/:workspace_id',
      name: 'link-user-workspace',
      beforeEnter: isSuperAdmin,
      component: () =>
        import(
          '@/views/pages/administration/workspaces/components/LinkUserWorkspace.vue'
        ),
    },

    // USERS ROUTER
    {
      path: '/users',
      name: 'users',
      beforeEnter: isSuperAdmin,
      component: () =>
        import('@/views/pages/administration/users/ListUsers.vue'),
      meta: {
        pageTitle: 'Lista de Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-user',
      name: 'new-user',
      beforeEnter: isSuperAdmin,
      component: () =>
        import('@/views/pages/administration/users/components/FormUser.vue'),
    },
    {
      path: '/update-user/:id',
      name: 'update-user',
      beforeEnter: isSuperAdmin,
      component: () =>
        import('@/views/pages/administration/users/components/UpdateUser.vue'),
    },
    {
      path: '/update-user-adm/:id/:adm_id',
      name: 'update-user-adm',
      beforeEnter: isSuperAdmin,
      component: () =>
        import('@/views/pages/administration/users/components/UpdateUser.vue'),
    },

    // SUPPLIERS ROUTE

    {
      path: '/suppliers',
      name: 'suppliers',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/suppliers/ListSuppliers.vue'),
      meta: {
        pageTitle: 'Lista de Fornecedores',
        breadcrumb: [
          {
            text: 'Fornecedores',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },

    {
      path: '/update-supplier/:id',
      name: 'update-supplier',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/suppliers/components/FormUpdateSupplier.vue'
        ),
    },
    // MY TASKS

    {
      path: '/minhas-tarefas',
      name: 'minhas-tarefas',
      //beforeEnter: isManager,
      component: () => import('@/views/pages/manager/myTasks/index.vue'),
      meta: {
        pageTitle: 'Minhas Tarefas',
        breadcrumb: [
          {
            text: 'Tarefas',
            active: false,
          },
          {
            text: 'Agenda',
            active: true,
          },
        ],
      },
    },

    {
      path: '/tarefas-da-equipe',
      name: 'tarefas-da-equipe',
      //beforeEnter: isManager,
      component: () => import('@/views/pages/manager/teamTasks/index.vue'),
      meta: {
        pageTitle: 'Tarefas da Equipe',
        breadcrumb: [
          {
            text: 'Tarefas da Equipe',
            active: false,
          },
          {
            text: 'Agenda',
            active: true,
          },
        ],
      },
    },
    // TAX RECORDS
    {
      path: '/taxes',
      name: 'taxes',
      beforeEnter: isFinancial,
      isFinancial,
      component: () => import('@/views/pages/financial/taxes/ListTaxes.vue'),
      meta: {
        pageTitle: 'Lista de Impostos',
        breadcrumb: [
          {
            text: 'Impostos',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-taxes',
      name: 'new-taxes',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/taxes/components/ModalTaxes.vue'),
    },
    {
      path: '/update-taxes/:id',
      name: 'update-taxes',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/taxes/components/UpdateTaxes.vue'),
    },
    {
      path: '/update-taxes-tax/:id/:tax_id',
      name: 'update-taxes-tax',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/taxes/components/UpdateTaxes.vue'),
    },

    // CUSTOS DE PROJETOS
    {
      path: '/custos-de-projeto',
      name: 'custos-de-projeto',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/projectCosts/ListProjectCosts.vue'),
      meta: {
        pageTitle: 'Custos de projeto',
        breadcrumb: [
          {
            text: 'Custos de Projeto',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-projectCosts',
      name: 'new-projectCosts',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/projectCosts/components/ModalProjectCosts.vue'
        ),
    },
    {
      path: '/update-projectCosts/:id',
      name: 'update-projectCosts',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/projectCosts/components/UpdateProjectCosts.vue'
        ),
    },
    {
      path: '/update-projectCosts-costs/:id/:cost_id',
      name: 'update-projectCosts-costs',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/projectCosts/components/UpdateProjectCosts.vue'
        ),
    },

    // CUSTOS DE ESCRITORIO
    {
      path: '/custos-de-escritorio',
      name: 'custos-de-escritorio',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/officeCosts/ListOfficeCosts.vue'),
      meta: {
        pageTitle: 'Custos de Escritório',
        breadcrumb: [
          {
            text: 'Custos de Escritório',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ]
      },
    },

    // COASTS CATEGORY
    {
      path: '/costsCategory',
      name: 'costsCategory',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/costsCategory/ListCostsCategory.vue'),
      meta: {
        pageTitle: 'Categoria de custos',
        breadcrumb: [
          {
            text: 'Custos',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },

    // PROJETOS
    {
      path: '/projetos',
      name: 'projetos',
      //beforeEnter: isManager,
      component: () =>
        import('@/views/pages/manager/projects/ListProjects.vue'),
      meta: {
        pageTitle: 'Projetos',
        breadcrumb: [
          {
            text: 'Projetos',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-projeto/:id',
      name: 'editar-projeto',
      //beforeEnter: isManager,
      component: () =>
        import('@/views/pages/manager/projects/components/ProjectsEdit.vue'),
    },
    {
      path: '/tarefas-projeto/:id',
      name: 'tarefas-projeto',
      meta: {
        pageTitle: 'Projetos',
        breadcrumb: [
          {
            text: 'Projeto',
            active: false,
            to: {name: 'projetos'}
          },
          {
            text: 'Tarefas do projeto',
            active: true,
          },
        ],
      },
      //beforeEnter: isManager,
      component: () =>
        import('@/views/pages/manager/projects/components/ListTasks.vue'),

    },
    {
      path: '/projetos/dashboard/:id',
      name: 'dashboard-projetos',
      meta:{
        breadcrumb: [
          {
            text: 'Projetos',
            active: false,
            to: {name: 'projetos'}
          },
          {
            text: 'Dashboard de projeto',
            active: true,
          },
        ],
      },

      //beforeEnter: isManager,
      component: () =>
        import(
          '@/views/pages/manager/projects/components/ProjectsDashboard.vue'
        ),
    },
    {
      path: '/novo-projeto',
      name: 'novo-projeto',
      meta: {
        pageTitle: 'Projetos',
        breadcrumb: [
          {
            text: 'Receita',
            active: false,
          },
          {
            text: 'Novo projeto',
            active: true,
          },
        ],
      },
      //beforeEnter: isManager,
      component: () =>
        import('@/views/pages/manager/projects/components/ProjectsAdd.vue'),
    },

    // Trocar workspace
    {
      path: '/trocar-workspace',
      name: 'trocar-workspace',
      //beforeEnter: isManager,
      component: () => import('@/views/pages/manager/quit/Quit.vue'),
    },
    // COLABORADORES
    {
      path: '/colaboradores',
      name: 'colaboradores',
      meta: {
        pageTitle: 'Colaborador',
        breadcrumb: [
          {
            name: 'colaboradores',
            text: 'Colaborador',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
      //beforeEnter: isManager,
      isManager,
      component: () => import('@/views/pages/manager/employee/index.vue'),
    },

    {
      path: '/editar-colaborador/:id',
      name: 'editar-colaborador',
      meta: {
        pageTitle: 'Colaborador',
        breadcrumb: [
          {
            name: 'colaboradores',
            text: 'Colaborador',
            active: false,
          },
          {
            text: 'editar',
            active: true,
          },
        ],
      },
      //beforeEnter: isManager,
      isManager,
      component: () =>
        import('@/views/pages/manager/employee/components/Update.vue'),
    },

    {
      path: '/incluir-novo-colaborador',
      name: 'incluir-novo-colaborador',
      meta: {
        pageTitle: 'Colaborador',
        breadcrumb: [
          {
            name: 'colaboradores',
            text: 'Colaborador',
            active: false,
          },
          {
            text: 'Adicionar novo',
            active: true,
          },
        ],
      },
      //beforeEnter: isManager,
      isManager,
      component: () =>
        import('@/views/pages/manager/employee/components/AddEmployee.vue'),
    },
    // LANÇAMENTO DE RECEITA
    {
      path: '/lancamento-de-receita',
      name: 'lancamento-de-receitas',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/revenue/ListRevenue.vue'),
      meta: {
        pageTitle: 'Lançamento de Receita',
        breadcrumb: [
          {
            text: 'Receita',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lancamento-de-receita/:id',
      name: 'lancamento-de-receita',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/revenue/components/RevenueDetails.vue'),
    },
    {
      path: '/editar-receita/:id',
      name: 'editar-receita',
      meta: {
        pageTitle: 'Receitas',
        breadcrumb:[
          {
            text: 'Receita',
            active: false,
          },
          {
            text: 'Editar',
            active: false
          },
          {
            text: '/:id',
            active: true
          }
        ]
      },
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/revenue/components/RevenueEdit.vue'),
    },
    {
      path: '/nova-receita',
      name: 'nova-receita',
      meta: {
        pageTitle: 'Nova receita',
        breadcrumb: [
          {
            text: 'Receita',
            active: false,
          },
          {
            text: 'Adicionar receita',
            active: true
          },
        ],
      },
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/revenue/components/RevenueAdd.vue'),
    },
    // LOGIN ROUTER
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    // CALCULO DE CUSTOS REAIS
    {
      path: '/calculo-de-custos',
      name: 'calculo-de-custos',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/calcDirectCosts/index.vue'),
      meta: {
        pageTitle: 'Calculo de custos',
        breadcrumb: [
          {
            text: 'Calculo de custos',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calculo-de-custos/cost-per-tasks',
      name: 'cost-per-tasks',
      beforeEnter: isFinancial,
      component: () =>
        import('@/views/pages/financial/calcDirectCosts/costPerTask.vue'),
      meta: {
        pageTitle: 'Calculo de custos',
        breadcrumb: [
          {
            text: 'Calculo de custos',
            active: false,
          },
          {
            text: 'Custos por tarefa',
            active: true,
          },
        ],
      },
    },
    // DEPRECIABLE ASSETS
    {
      path: '/depreciableAssets',
      name: 'depreciableAssets',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/depreciableAssets/ListDepreciableAssets.vue'
        ),
      meta: {
        pageTitle: 'Lista de bens depreciáveis',
        breadcrumb: [
          {
            text: 'bens depreciáveis',
            active: false,
          },
          {
            text: 'Lista',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-depreciableAssets',
      name: 'new-depreciableAssets',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/depreciableAssets/components/ModalDepreciableAssets.vue'
        ),
    },
    {
      path: '/update-depreciableAssets/:id',
      name: 'update-depreciableAssets',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/depreciableAssets/components/UpdateDepreciableAssets.vue'
        ),
    },
    {
      path: '/update-depreciableAssets-asset/:id/:asset_id',
      name: 'update-depreciableAssets-asset',
      beforeEnter: isFinancial,
      component: () =>
        import(
          '@/views/pages/financial/depreciableAssets/components/UpdateDepreciableAssets.vue'
        ),
    },

    // OTHERS ROUTER

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/exportar-dados',
      name: 'exportar-dados',
      //beforeEnter: isManager,
      component: () => import('@/views/pages/manager/exports/ExportData.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    // PROFILE
    {
      path: '/profile',
      name: 'profile',
      //beforeEnter: ifIsAuthenticated,
      component: () => import('@/views/pages/profile/Profile.vue'),
      meta: {
        pageTitle: 'Perfil',
        breadcrumb: [
          {
            text: 'Detalhes',
            active: true,
          },
        ],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.breadcrumb) {
    const breadcrumb = to.meta.breadcrumb.find(item => item.text === '/:id');
    if (breadcrumb) {
      breadcrumb.text = `Editando receita nº ${to.params.id}`;
    }
  }
  next();
});

function isManager(to, from, next) {
  const _user = store.getters.user.accesses[0];
  if (_user.is_manager) {
    ifIsAuthenticated(to, from, next);
  } else {
    next({ name: 'home' });
  }
}

function isFinancial(to, from, next) {
  const _user = store.getters.user.accesses[0];
  if (_user.is_transaction_manager) {
    ifIsAuthenticated(to, from, next);
  } else {
    next({ name: 'home' });
  }
}
function isSuperAdmin(to, from, next) {
  const _user = store.getters.user.accesses[0];
  if (_user.is_admin) {
    ifIsAuthenticated(to, from, next);
  } else {
    next({ name: 'home' });
  }
}

function ifIsAuthenticated(to, from, next) {
  if (ls.get('t')) {
    next();
  } else {
    next({ name: 'login' });
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
